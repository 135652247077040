import { getArticlePCpos, renderDisplayAd } from './utils';

/**
 * This function will be triggered on the client to initiate the caas module. It requires a number of
 * exposed properties on the window.YAHOO and window.rapidPageConfig objects.
 * @return {Void} Void
 */

(function (window) {
    const { adsConfig, DARLA, wafer, YAHOO } = window;

    const { meta = {} } = YAHOO.context;
    meta.spaceId = YAHOO?.i13n?.SPACEID;

    const CLUSTER_ADS_EVENT = 'clusterAds';

    if (DARLA && wafer) {
        wafer.ready(() => {
            wafer.on('caas:article:inview', _clusterArticleInViewHandler);
        }, window);
    }

    /**
     * article in view handler
     * @param {Object} event data received from in view event listener
     */
    function _clusterArticleInViewHandler(event) {
        // fetch and use right display ads on the caas body
        // Use right ad ids in caas Markup and render the right ad placement
        const { elem, meta: { data: { uuid = '' } = {} } = {} } = event || {};

        const articlePos = getArticlePCpos(elem);

        // First article's ad positions are not touched
        if (articlePos > 1) {
            const adPositions = [];

            const clusterAdId = articlePos - 1;
            _clusterLRECHandler(elem, clusterAdId, adPositions, uuid);
            _clusterLDRBHandler(elem, clusterAdId, adPositions);

            if (adPositions.length > 0) {
                renderDisplayAd(event, adPositions, CLUSTER_ADS_EVENT, true);
            }
        }
    }

    /**
     * handle lrec ad placements in cluster articles - These come along with caas body
     * @param {Object} elem cluster element in view
     * @param {Number} clusterAdId index of the ad
     * @param {String[]} adPositions  ad positions to render
     * @param {String} uuid of the article in view
     */
    function _clusterLRECHandler(elem, clusterAdId, adPositions, uuid) {
        // Caas markup always returns the ads with ids "sda-LREC"/"sda-LREC2"
        // replace the id with the right ad position(cluster-LREC-1/cluster-LREC-2
        // and cluster-LREC2-1/cluster-LREC2-2) ids for darla proxy to replace them
        // for LREC inside slide show we are using cluster-LREC3-1/cluster-LREC3-2
        ['LREC', 'LREC2', 'LREC3'].forEach((adPosition) => {
            const lrecIframeMarkup = elem.querySelector(
                `#caas-art-${uuid} #sda-${adPosition}-iframe`
            );

            if (lrecIframeMarkup && adsConfig?.positions?.[adPosition]?.meta) {
                const id = `${adPosition}-${clusterAdId}`;
                lrecIframeMarkup.parentElement.id = `cluster-${id}`;
                lrecIframeMarkup.id = `cluster-${id}-iframe`;
                adPositions.push(id);
                adsConfig?.clusterPositionMeta?.positions?.push({
                    ...adsConfig.positions[adPosition].meta,
                    clean: lrecIframeMarkup.parentElement.id,
                    dest: lrecIframeMarkup.id,
                    id,
                    pos: id,
                });
            }
        });
    }

    /**
     * handle ldrb ad placements in cluster articles
     * @param {Object} elem cluster element in view
     * @param {Number} clusterAdId index of the ad
     * @param {String[]} adPositions  ad positions to render
     */
    function _clusterLDRBHandler(elem, clusterAdId, adPositions) {
        const ldrb2Markup = elem.querySelector(`#cluster-LDRB2-${clusterAdId}`);

        if (ldrb2Markup) {
            adPositions.push(`LDRB2-${clusterAdId}`);
        }
    }
})(window);
