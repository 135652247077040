const { adsConfig, DARLA, location, rapidInstance, rapidPageConfig, YAHOO } =
    window;

// YAHOO.context is exposed via the kaizen-context component
const { context } = YAHOO;
const { meta = {}, feature } = context;
const { siteAttribute = '', url = '' } = meta;

// rapidPageConfig and SPACEID is exposed via the kaizen-rapid component
const { rapidConfig } = rapidPageConfig;
const i13nEntities = (rapidConfig && rapidConfig.keys) || {};
const spaceid = YAHOO.i13n && YAHOO.i13n.SPACEID;
const comscoreData = { referrer: '', spaceid, url };
let p_cpos_nxt = 0;

/**
 * Clone rapid object
 * @param {Object} obj rapid object
 * @returns {RapidConfig} cloned rapid object
 */
export function cloneRapid(obj: any) {
    const config: any = {};

    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            const val = obj[key];

            if (Array.isArray(val)) {
                config[key] = [];
                val.forEach((v: string) => {
                    config[key].push(v);
                });
            } else if (typeof val === 'function') {
                // don't do anything
            } else if (Object(val) === val) {
                // avoid recursive cloning as rapid objects are flat
                config[key] = Object.assign({}, val);
            } else {
                config[key] = val;
            }
        }
    }
    return config;
}

/**
 * This is util method to remove a node from its parent
 * @param {Node} node to be removed
 */
export function removeNode(node) {
    node?.parentNode?.removeChild(node);
}

/**
 * This is a utility method to get the current page url without query params
 * @returns {String} the current url
 */
export function getCurrentUrl() {
    let url = location.protocol + '//' + location.host;
    if (location.pathname) {
        url += location.pathname;
    }
    return url;
}

/**
 * This is an util method to fire a comscore pageview beacon
 * @param {Object} data info needed to fire comscore beacon
 * @return {Void} Void
 */
export function beaconComscore(data) {
    const { spaceid, url } = data;

    if (!spaceid || !url) {
        console.error('[comscore] spaceid or url is missing');
        return null;
    }

    // update the comscoreData and referrer if the url changed
    if (url !== comscoreData.url) {
        comscoreData.referrer = comscoreData.url;
        comscoreData.url = url;
        comscoreData.spaceid = spaceid;
    }

    // YAHOO.comscore is exposed via the kaizen-context component
    const { comscore } = YAHOO;
    const { c14 = -1, enableTracking = true } = comscore;
    if (!enableTracking) {
        return null;
    }

    const comscoreParams = {
        c1: '2',
        c14,
        c2: '7241469',
        c5: spaceid,
        c7: `${url}`,
        c9: comscoreData.referrer,
    };

    window._comscore = window._comscore || [];
    window._comscore.push(comscoreParams);
    window.COMSCORE && window.COMSCORE.purge();
}

/**
 * This is a handler method that listens to link click event and fires the click beacons
 * @param {Object} data received from event listener
 */
export function linkClickHandler(data) {
    const linkHref = data.href;
    const newWinCaas =
        (linkHref && window.open('', data.target || '_blank')) ||
        (linkHref && window);

    if (rapidInstance) {
        rapidInstance.beaconClick(
            data.sec,
            data.slk,
            data.pos || 0,
            data.params || {},
            data.outcm || null,
            () => {
                newWinCaas && (newWinCaas.location.href = linkHref);
            }
        );
    } else {
        newWinCaas && (newWinCaas.location.href = linkHref);
    }
}

/**
 * This is a util method to find the position of the article in the cluster
 * @param {Element} elem element for which position is being determined
 * @return {number} position of the given item
 */

export function getArticlePCpos(elem: Element) {
    if (!elem) {
        return 0;
    }
    const nodeList = document.querySelectorAll(
        'div.wafer-caas,div.wafer-caas-complete'
    );
    const articles = Array.from(nodeList);
    return articles.findIndex((article) => article === elem) + 1;
}

/**
 * This is a util method that takes care of updating the document title and pushing state of history
 * @param {string} title title of the article
 */
export const pushState = (title) => {
    // update page title and push into history
    if (title) {
        document.title = title;
        history.pushState(
            {
                state: history.state,
            },
            title
        );
    }
};

/**
 * This is a util method to fire page view beacons
 * @param {Object} data beacon data
 * @param {Element} elem element for which pageview is firing
 */
export function firePageViewBeacons(data, elem) {
    if (!rapidInstance) {
        return;
    }

    const id = elem && elem.id;

    const { contentType, publisher, spaceId, uuid } = data || {};

    // Clone the rapid config before making any additions / modifications
    const rapidConfig = cloneRapid(rapidPageConfig.rapidConfig);
    rapidConfig.spaceid = parseInt(spaceId, 10);

    const updatedRapidConfig: any = {
        pt: 'content',
    };

    const p_cpos: number = elem && getArticlePCpos(elem);

    // dont fire the page view for the first element;
    // since its already fired by the rapid, but in the inView cases we need it
    if (p_cpos === 1 && p_cpos_nxt === 0) {
        return;
    }

    if (p_cpos) {
        updatedRapidConfig.p_cpos = p_cpos;

        if (p_cpos > 1) {
            // use this key to track the cluster articles which are dynamically added
            updatedRapidConfig.expn = 'perpetual-post';
        }
    }

    if (uuid) {
        updatedRapidConfig.pstaid = uuid;
        updatedRapidConfig.pstaid_p = uuid;
    }

    if (publisher) {
        updatedRapidConfig.pcp = publisher;
    }

    if (contentType) {
        updatedRapidConfig.pct = contentType;
    }

    Object.assign(rapidConfig.keys, updatedRapidConfig);

    // fire pageview beacon
    if (YAHOO?.i13n) {
        YAHOO.i13n.SPACEID = rapidConfig.spaceid;
    }

    rapidInstance.reInit(rapidConfig);
    if (rapidInstance.isModuleTracked(id)) {
        rapidInstance.refreshModule(id, true, true);
    } else {
        rapidInstance.addModules(id, true, true);
    }

    // fire comscore beacon
    beaconComscore({
        spaceid: spaceId,
        url: getCurrentUrl(),
    });

    p_cpos_nxt = p_cpos + 1;
}

/**
 * This is a handler method that listens to slide change event and fires the necessary beacons
 * and inserts the gemini ads by making wafer-fetch calls
 * @param {Object} data received from event listener
 */
export const slideChangeHandler = (data) => {
    const slideshowPageParams = {
        pct: 'slideshow',
        pt: 'content',
    };

    if (rapidInstance) {
        rapidInstance.beaconPageview({
            ...i13nEntities,
            ...slideshowPageParams,
        });
        beaconComscore(comscoreData);
    }

    // refresh lrec ad on slide change
    if (DARLA) {
        DARLA.add('slideshowadfetch', {
            name: 'slideshowadfetch',
            npv: true,
            ps: 'LREC-2',
            sa: siteAttribute,
            sp: spaceid,
            ssl: true,
        });

        if (!DARLA.inProgress()) {
            DARLA.event('slideshowadfetch');
        }
    }
};

/**
 * This is a handler method that listens to mediacurrent(playlist item change) event and fires the
 * pageview beacon for the newly selected playlist item and saves the item in history
 * @param {Object} data received from event listener
 */
export const playlistMediaCurrentHandler = (data) => {
    // @ts-ignore
    const { id, provider, title } = data;
    if (id && title && provider) {
        const newVideoPageparams = {
            pstaid: id,
            pstaid_p: id,
        };

        if (provider) {
            // @ts-ignore
            newVideoPageparams.pcp = provider.name;
        }

        // fire page view beacon when current item in the playlist is changed with updated i13n params
        if (rapidInstance) {
            rapidInstance.beaconPageview({
                ...i13nEntities,
                ...newVideoPageparams,
            });
            beaconComscore(comscoreData);
        }

        // update page title and push into history
        pushState(title);
    }
};

/**
 * addModuleProgression to track the content progress
 * @param {string} module received
 */
export const contentProgression = (module) => {
    if (module && rapidInstance) {
        rapidInstance.addModuleProgression(module);
    }
};

/**
 * This is a handler method that listens to video interacted event and fires the
 * click beacon when the interaction is a click(action: playlistItemClick)
 * @param {Object} data received from event listener
 */
export const playlistInteractionHandler = (data) => {
    if (data) {
        const { action, index, title } = data;
        if (action === 'playlistItemClick' && rapidInstance) {
            rapidInstance.beaconClick('video', title, index, {}, null);
        }
    }
};

/**
 * get position config for given ad locations
 * @param {String[]} adPositions location id
 * @param {Boolean} isCluster boolean to check if its a cluster ad config
 * @return {String[]} filtered list of ad positions
 */
const getPositionConfig = (adPositions, isCluster) => {
    if (isCluster) {
        return adsConfig?.clusterPositionMeta?.positions.filter(
            (position) => adPositions.indexOf(position.id) !== -1
        );
    } else {
        if (adsConfig?.clientPositionMeta?.positions) {
            return adsConfig?.clientPositionMeta?.positions.filter(
                (position) => adPositions.indexOf(position.id) !== -1
            );
        }
        return adPositions.map(
            (position) => adsConfig?.positions[position]?.meta
        );
    }
    return [];
};

/**
 * Render Display Ad unit
 * @function fetchAds
 * @param {Object} event data received from in event listener
 * @param {String[]} adPositions  ad positions to render
 * @param {String} adEventName ad event name
 * @param {Boolean} isCluster boolean to chcek if its a cluster ad config
 * */
const fetchAds = (event, adPositions, adEventName, isCluster) => {
    const partnerData = event?.meta?.data;
    const adMeta = partnerData?.adMeta;
    let qpSpaceId = '';
    if (window.vzm?.getPageContext()?.ynet) {
        if (location?.search?.indexOf('_spaceid') !== -1) {
            const autoEventSettings = DARLA?.evtSettings('AUTO');
            qpSpaceId = autoEventSettings.sp;
        }
    }
    let siteAttributes = adMeta?.site_attribute || '';

    if (isCluster && adsConfig.clusterPositionMeta?.siteAttributes) {
        siteAttributes =
            siteAttributes + ' ' + adsConfig.clusterPositionMeta.siteAttributes;
    } else {
        const autoEventSettings = DARLA.evtSettings('AUTO');
        siteAttributes = autoEventSettings.sa;
    }

    if (feature.indexOf('enableMLWAfterArticle') !== -1) {
        const hashTagString = 'hashtag="';
        const hashTagIndex = siteAttributes.indexOf(hashTagString);
        const splitIndex = hashTagString.length + hashTagIndex;
        if (hashTagIndex !== -1) {
            siteAttributes =
                siteAttributes.slice(0, splitIndex) +
                'widget-test;' +
                siteAttributes.slice(splitIndex, siteAttributes.length);
        } else {
            siteAttributes += ' hashtag="widget-test;"';
        }
    }

    // get the position configs for the requested ad positions
    const positionConfig = getPositionConfig(adPositions, isCluster);
    const ps = [];
    const hostURL = getCurrentUrl();

    if (positionConfig?.length) {
        // add position configs to DARLA position settings
        for (const position of positionConfig) {
            ps.push(position.id);
            if (!position.meta) {
                position.meta = {};
            }
            position.meta.hostURL = hostURL;
            DARLA.addPos(position);
        }

        // Make a darla call to update the placeholder markup on the page with the right ad markup
        DARLA.add(adEventName, {
            name: adEventName,
            ps: adPositions,
            ref: location.href,
            sa: siteAttributes,
            sp: adMeta?.spaceid || qpSpaceId || spaceid,
        });
        DARLA.event(adEventName);
    }
};

/**
 * if there are pageload ads still loading, we will hold off and retry to load ads after pageload ads are done loading
 * @function fetchAdsLater
 * @param {Object} event data received from in event listener
 * @param {String[]} adPositions  ad positions to render
 * @param {String} adEventName  ad event name
 * @param {Boolean} isCluster boolean to check if its a cluster ad config
 * */
const fetchAdsLater = (event, adPositions, adEventName, isCluster) => {
    let retry = 0;
    const retryTimer = setInterval(function () {
        // abort the auto events
        if (DARLA.inProgress() && DARLA.inProgress() === 'AUTO') {
            DARLA.abort();
        }

        if (!DARLA.inProgress()) {
            clearInterval(retryTimer);
            fetchAds(event, adPositions, adEventName, isCluster);
            return;
        }

        if (retry >= 20) {
            clearInterval(retryTimer);
            // abort after 10 seconds
            if (DARLA.inProgress()) {
                DARLA.abort();
                fetchAds(event, adPositions, adEventName, isCluster);
            }
        }
        retry++;
    }, 500);
};

/**
 * Render Display Ad unit
 * @param {Object} event data received from in event listener
 * @param {String[]} adPositions  ad positions to render
 * @param {String} adEventName  ad event name
 * @param {Boolean} isCluster boolean to check if its a cluster ad config
 * */
export const renderDisplayAd = (
    event,
    adPositions,
    adEventName,
    isCluster = false
) => {
    if (!adsConfig) {
        return;
    }

    if (!DARLA) {
        return;
    }

    if (DARLA.inProgress()) {
        // if the previous darla call is in progress, retry later
        fetchAdsLater(event, adPositions, adEventName, isCluster);
    } else {
        fetchAds(event, adPositions, adEventName, isCluster);
    }
};
